<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import Master from "@/apis/Master";
import Swal from "sweetalert2";
import Vue from "vue";
/**
 * Orders Component
 */
export default {
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "PRODUCT SPECIFICATION FILTERS",
      items: [
        {
          text: "DASHBOARD",
          href:"/"
        },
        {
          text: "PRODUCT SPECIFICATION FILTERS",
        }
      ],
      checkbox:[],
      isCheckAll:false,
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      selected:"true",
      fields: [
        { key: "checkbox", sortable: false, label: ""},
        { key: "listSubMenuName", sortable: true, label: "LIST SUB MENU",thStyle: { color: "black", "font-size":"16px"}  },
        { key: "specification", sortable: false, label: "SPECIFICATIONS",thStyle: { color: "black", "font-size":"16px"}  },
        { key: "filter", sortable: false, label: "FILTERS",thStyle: { color: "black", "font-size":"16px"}  },
        { key: "action", label: "ACTION",thStyle: { color: "black", "font-size":"16px"}  }
      ],
      listSubmenuFiltersAndSpecificationData: [],
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.listSubmenuFiltersAndSpecificationData.length;
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.listSubmenuFiltersAndSpecificationData.length;

    Master.listSubmenuFiltersAndSpecificationList().then((res) => {
      console.log(res)
      this.listSubmenuFiltersAndSpecificationData = res.data.data;
    })


  },

  methods: {
    /**
     * Edit Color Modal
     */
    checkAll: function(){
      console.log(this.perPage)
      this.isCheckAll = !this.isCheckAll;
      this.checkbox = [];
      if(this.isCheckAll){ // Check all
        for (var key in this.listSubmenuFiltersAndSpecificationData) {
          console.log(key,this.listSubmenuFiltersAndSpecificationData[key].id)
          this.checkbox.push(this.listSubmenuFiltersAndSpecificationData[key].id);
        }
      }
    },
    async bulkDelete(){
      if (this.checkbox[0] == null){
        alert("Please select atleast one checkbox")
      }else {
        if (confirm('Are you sure you want to delete this item?')) {
          var userId = JSON.parse(localStorage.getItem('user'))
          Master.listSubmenuFiltersAndSpecificationBulkDelete({
            ids: this.checkbox,
            userId: userId.id,
          }).then((res) => {
            Vue.swal({
              position: "center",
              icon: "success",
              title: "<p style='color: red'>"+res.data.message+"</p>",
              showConfirmButton: false,
              timer: 1500
            });
            this.checkbox = []
            this.isCheckAll = false
            Master.listSubmenuFiltersAndSpecificationList().then((res) => {
              console.log(res)
              this.listSubmenuFiltersAndSpecificationData = res.data.data;
            })
          })
        }
      }
    },
    async editPage(data){
      localStorage.setItem("listSubMenuId",data);
    },
    async deleteListSubMenu(data){
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!"
      }).then(result => {
        if (result.value) {
          Master.listSubmenuFiltersAndSpecificationDelete(
              {
                listSubmenuId: data
              }).then(res => {
            console.log(res)
            Swal.fire("Deleted!", "Your data has been deleted.", "success");
            // this.successMessage = res.data.message;
            Master.listSubmenuFiltersAndSpecificationList().then((res) => {
              console.log(res)
              this.listSubmenuFiltersAndSpecificationData = res.data.data;
            })
          })
        }
      });
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<template>
  <Layout>

    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body" style="text-transform: uppercase !important;">
            <div class="btn-toolbar">
              <router-link to="/master-data/product-specification-filter/add"> <a href="javascript:void(0);" class="btn btn-primary mb-2 mr-1"  @click="showmodal = true">
                <i class="mdi mdi-plus mr-2"></i> Add
              </a></router-link>
              <a href="javascript:void(0);" class="btn btn-danger mb-2 mr-1" @click="bulkDelete">
                <i class="mdi mdi-trash-can mr-2"></i> Delete
              </a>
            </div>

            <b-tabs nav-class="nav-tabs-custom">
              <div class="row mt-4">
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_length" class="dataTables_length">
                    <label class="d-inline-flex align-items-center">
                      Show&nbsp;
                      <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                    </label>
                  </div>
                </div>
                <!-- Search -->
                <div class="col-sm-12 col-md-6">
                  <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                    <label class="d-inline-flex align-items-center">
                      Search:
                      <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                  </div>
                </div>
                <!-- End search -->
              </div>
              <div class="table-responsive">
                <b-table
                    class="table-centered"
                    :items="listSubmenuFiltersAndSpecificationData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    show-empty
                    striped
                    sort-icon-left

                >
                  <template v-slot:head(checkbox)>
                    <div class="form-check mb-1">
                      <input
                          type="checkbox"
                          class="form-check-input"
                          v-model="isCheckAll"
                          :id="`customercheck`"
                          @click='checkAll()'
                      />
                      <label class="form-check-label" :for="`customercheck`"></label>
                    </div>
                  </template>



                  <template v-slot:cell(checkbox)="row">
                    <div class="form-check mb-3">
                      <input
                          type="checkbox"
                          class="form-check-input"
                          v-model="checkbox"
                          :id="`customercheck${row.index}`"
                          :value="row.item.id"
                      />
                      <label class="form-check-label" :for="`customercheck${row.index}`">&nbsp;</label>
                    </div>
                  </template>


                  <template v-slot:cell(specification)="row">
                    <p  class="badge font-size-12">
                        {{ row.item.specifications.map(entry => entry.specificationName).join(', ')}}
                    </p>
                  </template>


                  <template v-slot:cell(listSubMenuName)="row">
                    <p  class="badge font-size-12" v-show="row.item.specifications != []" >
                      {{row.item.name}}
                    </p>
                  </template>



                  <template   v-slot:cell(filter)="row">
                    <p  class="badge font-size-12">
                    {{ row.item.filters.map(entry => entry.filterName).join(', ')}}
                    </p>
                  </template>
                  <template v-slot:cell(status)="row">
                    <div v-if="row.value == 1"
                         class="badge font-size-12 badge-soft-success"
                    >ACTIVE</div>
                    <div v-if="row.value == 0"
                         class="badge font-size-12 badge-soft-danger"
                    >IN-ACTIVE</div>
                  </template>
                  <template v-slot:cell(action) = "data">
                    <router-link to="/master-data/product-specification-filter/edit" ><a
                        href="javascript:void(0);"
                        class="mr-3 text-primary"
                        @click="editPage(data.item.id)"
                        v-b-tooltip.hover
                        title="Edit"
                    >
                      <i class="mdi mdi-pencil font-size-18"></i>
                    </a></router-link>
                    <a
                        href="javascript:void(0);"
                        class="text-danger"
                        @click="deleteListSubMenu(data.item.id)"
                        v-b-tooltip.hover
                        title="Delete"
                    >
                      <i class="mdi mdi-trash-can font-size-18"></i>
                    </a>
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col">
                  <div class="dataTables_paginate paging_simple_numbers float-right">
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
              <!--              </b-tab>-->
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>